<template>
  <span>
    <b-form-input v-model="input_validated" @focus="changePnr" v-show="validated"></b-form-input>

    <IMaskComponent
      :key="edited" 
      v-show="!validated"
      class="form-control"
      autocomplete="off"
      v-model="input_value"
      :mask="mask"
      ref="pnr"
      radix="."
      :autofix="autofix"
      :overwrite="overwrite"
      @blur="hidePnr"
      @accept="acceptData"
      @complete="completeData"
      :placeholder="$t('COMPOSE.LINK.SPECIFY.INPUT_PNR')"
    ></IMaskComponent>
  </span>
</template>
<script>
 import {IMaskComponent} from 'vue-imask';
export default {
  components: { 
    IMaskComponent
  },
  props: ["value"],
  data() {
    return {
      edited: false,
      input_validated: '',
      validated: false,
      input_value: this.value,
      overwrite: true,
      autofix: true,
      mask: "00000000-0000"
    };
  },
  methods: {
    validatePNum(sPNum) {
      sPNum = sPNum.replace("-", "");
      var numbers = sPNum.match(
        /^(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/
      );
      var checkSum = 0;
      if (
        !this.isDate(
          sPNum.substring(0, 4),
          sPNum.substring(4, 6),
          sPNum.substring(6, 8)
        )
      ) {
        return false;
      }
      if (numbers == null) {
        return false;
      }
      var n;
      for (var i = 3; i <= 12; i++) {
        n = parseInt(numbers[i]);
        if (i % 2 == 0) {
          checkSum += n;
        } else {
          checkSum += ((n * 2) % 9) + Math.floor(n / 9) * 9;
        }
      }
      if (checkSum % 10 == 0) {
        return true;
      }
      return false;
    },
    hidePnr()
    {
      if (this.validatePNum(this.input_value)) {
        let retVal = {
          value: this.input_value,
          data: {
            pnr: this.input_value
          }
        };
        this.validated = true;
        this.input_validated = this.input_value.split('-')[0] + "-XXXX";
        this.$emit("changed", retVal);
        this.$emit("valid",true);
      } else {
        this.$emit("valid",false);
      }
    },
    changePnr()
    {
      let self = this;
      this.validated = false;
      setTimeout(function(){
        self.$refs['pnr'].$el.focus();
      }, 70);
     },
    getYear(y) {
      return y < 1000 ? y + 1900 : y;
    },
    isDate(year, month, day) {
      month = month - 1;
      var tmpDate = new Date(year, month, day);
      if (
        this.getYear(tmpDate.getYear()) == year &&
        month == tmpDate.getMonth() &&
        day == tmpDate.getDate()
      )
        return true;
      else return false;
    },
    acceptData: function() {
      this.$emit("valid",false);
      let retVal = {
        value: '',
        data: {
          pnr: ""
        }
      };
      this.$emit("changed", retVal);
    },
    completeData: function(value) {
      if (this.validatePNum(value)) {
        let retVal = {
          value: value,
          data: {
            pnr: value
          }
        };
        this.validated = true;
        this.input_validated = value.split('-')[0] + "-XXXX";
        this.$emit("changed", retVal);
        this.$emit("valid",true);
      } else {
        this.$emit("valid",false);
      }
    },
    formatter(value) {
      return value.toLowerCase();
    },
  },
  watch: { 
    input_value: function(newVal) {
      let self = this;
      if( (newVal.includes("-") && newVal.length == 11) )
      {          
        if(!newVal.startsWith("1") && !newVal.startsWith("2") )
        {
          this.input_value = "19" + newVal;
          this.edited = this.edited + 1;
          if(self.$refs['pnr'].$el != undefined)
          {
            setTimeout(function(){ self.$refs['pnr'].$el.focus(); }, 100);
          }
        }
      }      
    }
  },
};
</script>
<style></style>